import './footer.css'
import React from 'react'


function Footer() {
    return (
       <footer>
           <span>© Henning Ahrens 2022 - All rights reserved.</span>
       </footer>
    );
}

export default Footer